import * as api from '../api/index.js' 


export const createSubscriber = (email) => async(dispatch) => {

    try{
        const data = await api.createSubscriber(email);
        return data;

    }catch(error){
        console.log(error.message);
    }
}

// export const createWhitelister = (whitelisterdata) => async(dispatch) => {

//     try{
//         const data = await api.createWhitelister(whitelisterdata);
//         return data;

//     }catch(error){
//         console.log(error.message);
//     }
// }

// export const getWhitelister = (whitelisterdata) => async(dispatch) => {

//     try{
//         const data = await api.getWhitelister(whitelisterdata);
//         return data;

//     }catch(error){
//         console.log(error.message);
//     }
// }