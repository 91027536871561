import React from 'react';
import { Container } from '@mui/material';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import KittycatNFTHome from './pages/home';

// import NFTHEVWhitelist from './pages/whitelist';


import { Header, Footer, Footerinfo } from './containers';
import useStyles from './styles';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers'

function getLibrary(provider) {
    return new ethers.providers.Web3Provider(provider);
}

const App = ()=> {
    const classes = useStyles();


    return(   
        <BrowserRouter>
            <Container className={classes.container} disableGutters maxWidth={false}>
                <Web3ReactProvider getLibrary={getLibrary}>
                
                <Header/>
            
                <Switch> 
                <Route path="/" exact component={KittycatNFTHome} />
                
                {/* <Route path="/whitelist" exact component={NFTHEVWhitelist}/> */}
               
                
                <Route component={KittycatNFTHome}/>
                </Switch> 
                </Web3ReactProvider>
           
                <Footer/> 
            </Container>
        </BrowserRouter>
    )
};

export default App;

