import {  FETCH_WHITELISTER } from '../constants/actionTypes';

const users = (state = { isLoading: true, whitelister: [] }, action) => {
  switch (action.type) {  

    case 'START_LOADING':
      return { ...state, isLoading: true };
      
    case 'END_LOADING':
      return { ...state, isLoading: false };

    case FETCH_WHITELISTER:
      return {
        ...state,
        whitelister: action.payload.data,
      };
    
    default:
      return state;
  }
};

export default users;