import React from 'react';
import styled from "styled-components";
import { BsInstagram, BsMedium, BsDiscord, BsYoutube, BsReddit } from "react-icons/bs";
import { GrTwitter } from "react-icons/gr";
import { Colors, Devices } from "../Theme/KittyNftTheme/Theme";
import {FooterEl, Socials, CopyRight, Links, Ahref } from './Style';
import {SiteName } from '../../utils/config';

export default function Footercomp() {
  return (
    <FooterEl>
      <Socials>
        <Ahref href="https://www.instagram.com/nfthev.io" target="_blank" rel="noreferrer">
          <BsInstagram />
        </Ahref>
        <Ahref href="https://twitter.com/nfthev" target="_blank" rel="noreferrer">
          <GrTwitter />
        </Ahref>
        <Ahref href="https://medium.com/@nfthev" target="_blank" rel="noreferrer">
          <BsMedium />
        </Ahref>
        <Ahref href="https://discord.gg/UK3Rxjcxyy" target="_blank" rel="noreferrer">
          <BsDiscord />
        </Ahref>
        <Ahref href="https://www.reddit.com/r/nfthev" target="_blank" rel="noreferrer">
          <BsReddit />
        </Ahref>
       
        <Ahref href="https://www.youtube.com/@nfthev/featured" target="_blank" rel="noreferrer">
          <BsYoutube/>
        </Ahref>
      </Socials>
      <CopyRight>
        Copyright &copy; {new Date().getFullYear()} {SiteName}.com. All rights reserved.
      </CopyRight>
      <Links>
       
      </Links>
    </FooterEl>
  );
}