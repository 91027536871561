import styled from "styled-components";
import { Colors, Devices } from "../Theme/KittyNftTheme/Theme";

export const HeaderEl = styled.header`
  z-index: 10;
  display: flex;
  color: ${Colors.White};
  width: 100%;
  align-items: center;
  //height: 10%;
  gap: 1rem;
  padding: 1rem 1.5rem;
  top: 0;
  //background-color: ${Colors.Background};
  background-color: ${(p) => (p.as ? Colors.Background : "")};
  position: ${(p) => (p.as ? "fixed" : "absolute")};
  svg {
    font-size: 2rem;
    cursor: pointer;
  }
`;

export const Center = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const LogoText = styled.a`
  font-size: 1.2rem;
  font-weight: 500;
  color: #bbbbbb;
`;

export const Logo = styled.img`
  width: 165px;
  border-right: 1px solid ${Colors.Gray};
  padding-right: 1rem;
`;

export const Nav = styled.nav`
  margin-left: auto;
  border-right: 1px solid ${Colors.Gray};
  padding-right: 1rem;
  display: none;
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 1rem;
  }
  @media ${Devices.Laptop} {
    display: block;
  }
`;

export const NavItem = styled.a`
  font-size: 1rem;
  font-weight: 400;
  color: ${Colors.Red};
`;

export const Ulist = styled.ul`
  margin-bottom: auto;
`;

export const SearchIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Red};
  @media ${Devices.Laptop} {
    display: none;
  }
`;
export const MenuIcon = styled(SearchIcon)``;

export const AuthItems = styled(NavItem)`
  font-size: 1rem;
  display: none;
  @media ${Devices.Laptop} {
    display: inherit;
  }
`;

export const Buttonone = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(
    to right,
    ${Colors.Gradients.PrimaryToSec[0]},
    ${Colors.Gradients.PrimaryToSec[1]}
  );
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  color: ${Colors.White};
  border: none;
  border-radius: ${(p) => (p.round ? "50px" : "50px")};
  width: max-content;
`;

export const Buttontwo = styled.button`
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${Colors.Red};
  padding: 0.5rem 1.0rem;
  font-weight: 500;
  color: ${Colors.White};
  border: none;
  white-space: nowrap;
  border-radius: ${(p) => (p.round ? "50px" : "10px")};
  width: max-content;
  @media ${Devices.Laptop} {
    display: flex;
  }
`;

export const Buttonthree = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${Colors.Red};
  padding: 0.5rem 1.0rem;
  font-weight: 500;
  color: ${Colors.White};
  border: none;
  border-radius: ${(p) => (p.round ? "50px" : "10px")};
  width: max-content;
  @media ${Devices.Laptop} {
    display: none;
  }
`;

export const Mobilediv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @media ${Devices.Laptop} {
    display: none;
  }
`;

export const Laptopdiv = styled.div`
  flex: 1;
  display: none;
  align-items: center;
  gap: 0.5rem;
  @media ${Devices.Laptop} {
    display: flex;
  }
`;