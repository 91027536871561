import React from 'react';
import './joincommunity.css';
import JoinCommunity from '../../../components/Cards/JoinCommunity/JoinCommunity'


export const Joincommunity = ()=> {

    return (
        <JoinCommunity/>
    )
};

export default Joincommunity;