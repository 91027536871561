import React from 'react';
import './joincommunity.css';
import WhitelistLinkCard from '../../../components/Cards/JoinCommunity/WhitelistLink'


export const WhutelistLink = ()=> {

    return (
        <WhitelistLinkCard/>
    )
};

export default WhutelistLink;