import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import './introduction.css';
import Intro from "../../../components/Cards/Intro/BannerArea1";

const Introduction = ()=> {

    return (
        <div>
            <Intro/>
        </div>
    )   
};

export default Introduction;