import React from 'react';
import { Link} from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3React } from "@web3-react/core";




const BannerArea1 = () => {
  const [days, setDays] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  
  const comingSoonTime = () => {
    let endTime = new Date('July 20, 2023 17:00:00 PDT');
    let endTimeParse = Date.parse(endTime) / 1000;
    let now = new Date();
    let nowParse = Date.parse(now) / 1000;
    let timeLeft = endTimeParse - nowParse;
    let countdays = Math.floor(timeLeft / 86400);
    let counthours = Math.floor((timeLeft - countdays * 86400) / 3600);
    let countminutes = Math.floor(
      (timeLeft - countdays * 86400 - counthours * 3600) / 60
    );
    let countseconds = Math.floor(
      timeLeft - countdays * 86400 - counthours * 3600 - countminutes * 60
    );
    if (counthours < '10') {
      counthours = '0' + counthours;
    }
    if (countminutes < '10') {
      countminutes = '0' + countminutes;
    }
    if (countseconds < '10') {
      countseconds = '0' + countseconds;
    }

    setDays(countdays);
    setHours(counthours);
    setMinutes(countminutes);
    setSeconds(countseconds);
  };

  useEffect(() => {
    setInterval(() => {
      comingSoonTime();
    }, 1000);
  }, []);


  return (
    <>
      <div className='banner-area-three'>
        <div className='container'>
          <div className='banner-content-three'>
            <h1>NFTHEV NFT Marketplace Coming Soon</h1>
            <p>
            The home of the world's most unique and valuable non-fungible tokens (NFTs).
            </p>

            {/* <div id='timer'>
              <div id='days'>{days}<span>days</span></div>
              <div id='hours'>{hours}<span>hours</span></div>
              <div id='minutes'>{minutes}<span>minutes</span></div>
              <div id='seconds'>{seconds}<span>seconds</span></div>
            </div> */}

            <div className='banner-btn'>
              {/* <Link href='/whitelist'>
                <a className='default-btn border-radius-5'>Get Whitelisted</a>
              </Link> */}

              <Link href='https://testnet.nfthev.io'>
                <a className='default-btn two border-radius-5'>Testnet</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerArea1;
