export const CONNECT = 'CONNECT';
export const LOGOUT = 'LOGOUT';
export const FETCH = 'FETCH';
export const LIKE = 'LIKE';
export const CREATE = 'CREATE';
export const FETCH_ALL = 'FETCH_ALL';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_BY_CATEGORY = 'FETCH_BY_CATEGORY';
export const FETCH_BY_FEATURES = 'FETCH_BY_FEATURES';
export const FETCH_CATS = 'FETCH_CATS';
export const FETCH_NFTS = 'FETCH_NFTS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const FETCH_USER = 'FETCH_USER';
export const UPLOADED_FILES = 'UPLOADED_FILES';
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_CONNECTED_USER = 'FETCH_CONNECTED_USER';
export const FETCH_NFTDROPS = 'FETCH_NFTDROPS';
export const FETCH_DROP_BY_CATEGORY = 'FETCH_DROP_BY_CATEGORY';
export const FETCH_DROP_BY_SEARCH = 'FETCH_DROP_BY_SEARCH'
export const FETCH_OWNER = 'FETCH_OWNER';
export const FETCH_DROP = 'FETCH_DROP';
export const FETCH_COLLECTION = 'FETCH_COLLECTION';
export const LIBRARY = 'LIBRARY';
export const PROVIDER = 'PROVIDER';
export const FETCH_OWNER_COLLECTION = 'FETCH_OWNER_COLLECTION';
export const FETCH_DROP_BY_BLOCKCHAIN = 'FETCH_DROP_BY_BLOCKCHAIN';
export const FETCH_BY_BLOCKCHAIN = 'FETCH_BY_BLOCKCHAIN';
export const FETCH_WHITELISTER = 'FETCH_WHITELISTER';
//export const CONNECTWALLET = 'CONNECTWALLET';
