import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderEl, Center, LogoText, Logo, Nav, NavItem, SearchIcon, MenuIcon, AuthItems, Buttontwo, Buttonthree, Ulist, Mobilediv, Laptopdiv } from './Style'
import { FiMenu } from "react-icons/fi";
import { CgSearch } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
//import MuiAlert from '@material-ui/lab/Alert';
// import Alert from '@mui/material/Alert';

import { AppBar, Snackbar, Toolbar, Button, MenuItem, Grow, ButtonGroup, Popper, Paper, ClickAwayListener, MenuList, Grid, Link, Avatar, Box, IconButton, ListItemIcon, Menu, Typography, Tooltip, TextField } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CollectionsIcon from '@mui/icons-material/Collections';
import CreateIcon from '@mui/icons-material/Create';
import Logout from '@mui/icons-material/Logout';
import SearchBar from "./SearchBar";
import SearchBarMobile from "./SearchBarMobile"
import { Colors, Devices } from "../Theme/KittyNftTheme/Theme"
import { useWeb3React } from "@web3-react/core"
import { injected, resetWalletConnector, walletconnect } from '../Blockchain/Connector';
import { getConnectedUser } from '../../actions/users';
import * as actionType from '../../constants/actionTypes';

// import { styled, alpha } from '@mui/material/styles';
import { styled, alpha } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import AppsIcon from '@mui/icons-material/Apps';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(5.5),
      minWidth: 143,
      display: 'none',
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '@media only screen and (min-width: 1024px)':{
        display: 'flex',
          },
        '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));


const StyledMenuMobile = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(5.5),
      minWidth: 240,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '@media only screen and (min-width: 1024px)':{
            display: 'none',
          },
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const Headercomp = ({mobileMenu})=>{
    const { active, account, library, connector, activate, deactivate, error } = useWeb3React();
    const {connecteduser} = useSelector((state) => state.users);
    const { MobileMenuIsOpen, setMobileMenuIsOpen } = mobileMenu;
    const [SearchIsOpen, setSearchIsOpen] = useState(false);
    const [sticky, setSticky] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile'))); 
    const [snackmess, setSnackmess] = useState(undefined); 
    const [severity, setSeverity] = useState('success');
    const [opensnack, setOpensnack] = React.useState(false);
    //const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
   
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElMobile, setAnchorElMobile] = useState(null);
    const open = Boolean(anchorEl);
    const openMobile = Boolean(anchorElMobile)
   
    // function Alert(props) {
    //     return <MuiAlert elevation={6} variant="filled" {...props} />;
    // }

   

    const handleSnack = (mess, severity) => {
        setOpensnack(true);
        setSnackmess(mess);
        setSeverity(severity);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpensnack(false);
    };

    //const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    // const handleClickMobile = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorElMobile(event.currentTarget);
    // };

    const handleClickMobile = (event) => {
        //console.log(event.currentTarget);
        setAnchorElMobile(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleCloseMobile = () => {
        setAnchorElMobile(null);
    };


    function toggleMenu() {
            setMobileMenuIsOpen(!MobileMenuIsOpen);
    }

    const logout = async () => {
        await deactivate();
        dispatch({ type: actionType.LOGOUT });
        handleSnack('Please connect to MetaMask.','error');
        setUser(null);
        // history.push('/');
        return location.pathname
    };

    const openMyNFTs = (e) => {
        history.push(`/mynfts`);
    };

    const openCreate = (e) => {
        history.push(`/create`);
    };

    const openMyCollections = (e) => {
      history.push(`/mynfts/collections`)
    }

    const handleProfile = (e) => {
        history.push(`/user/${account}`);
      };

    const handleCreateProfile = (e) => {
      history.push(`/profile/create`);
    };
    
    // const openConnect = (e) => {
    //    // history.push(`/connect`);
    //     //ConnectWalletConnect()
    // };
    
    const handleBrowse = (page) => {
       page==='nftdrop' ? history.push(`/browse/nftdrop?page=1&sort=1`) : history.push(`/browse?page=1&sort=1`);
    };

    const ConnectWallet = async()=> {
        try {
            await activate(injected)
            dispatch({ type: actionType.CONNECT, payload:'connected' });
        } catch (ex) {
            console.log(ex)
        }
    }

    const showStickyMenu = () => {
        if (window.scrollY >= 60) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };
    
    if (typeof window !== 'undefined') {
        // browser code
        window.addEventListener('scroll', showStickyMenu);
    }

    useEffect(() => {
        //dispatch(getConnectWallet(setConnectWallet));
        if(JSON.parse(localStorage.getItem('profile'))){
            ConnectWallet();
            dispatch(getConnectedUser(JSON.parse(localStorage.getItem('profile'))));
        }
      }, []);

    useEffect(() => {
        if(account){
            setUser(account);
            dispatch(getConnectedUser(account));
            localStorage.setItem('profile', JSON.stringify(account));
            
        }else{
          // console.log(error)
            //dispatch({ type: actionType.LOGOUT });
            //handleSnack('Please connect to MetaMask.','error');
            setUser(null);
        }
    }, [account]);

    return (
         
       <HeaderEl as={sticky===true?'span':''}>

            <Snackbar open={opensnack} anchorOrigin={{ vertical:'top', horizontal:'center' }} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity={severity}>
                    {snackmess}
                </Alert>
            </Snackbar>

            <Center>
                <a href='/'><Logo src="/images/logo/LogoBeta.png" /></a>
                {/* <LogoText href="/">KNM NFT MARKETPLACE</LogoText> */}
            </Center>

        </HeaderEl>
       

    )
}

export default Headercomp;