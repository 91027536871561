import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';  
import { createStore, applyMiddleware, compose } from 'redux'; 
import thunk from 'redux-thunk';

import { reducers } from './reducers';

import App from './App';


import './index.css';
import './bootstrap.min.css';
import './responsive.css';
import './responsive.css.map';
import './style.css';
import './style.css.map';


const store = createStore(reducers, {}, compose(applyMiddleware(thunk)));


ReactDOM.render(
  
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
);

