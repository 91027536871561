import { combineReducers } from 'redux';


import users from './users';

import whitelist from './whitelist';

export const reducers =  combineReducers({
    users: users,
    whitelist: whitelist,   
});

