import axios from 'axios';

const API = axios.create({ baseURL: 'https://api.nfthev.io' });
//const API = axios.create({ baseURL: 'http://localhost:3002' });


export const createUser = (newUser)=> API.post(`/users/create`, newUser);
export const checkIfLiked = (userdata)=> API.post(`/users/checkifliked`, userdata);
export const uploadimage = (image) => API.post(`/users/uploadimage`, image,{ headers:{
          'Content-Type': 'multipart/form-data'}
        });
export const checkIfLikedForDrops = (userdata)=> API.post(`/users/checkiflikedfordrops`, userdata);
export const updateUser = (user) => API.patch(`/users/update`, user);
export const getUser = (user) => API.get(`/users/${user}`);
export const updateLike = (userdata) => API.patch(`/users/updatelike`, userdata);
export const updateDropLike = (userdata) => API.patch(`/users/updatedroplike`, userdata);


export const createSubscriber =(email)=>API.post(`/nfthevsubscribe/create`, email);

export const createWhitelister =(whitelisterdata)=>API.post(`/nfthevwhitelist/whitelistcreate`, whitelisterdata, { headers:{
  'Content-Type': 'multipart/form-data'}});
export const getWhitelister =(whitelisteraddy)=>API.post(`/nfthevwhitelist/getwhitelister`, whitelisteraddy, { headers:{
  'Content-Type': 'multipart/form-data'}});
export const updateWhitelister = (data) => API.patch(`/nfthevwhitelist/whitelistupdate`, data);




