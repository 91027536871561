import React, { useEffect } from 'react';
import { Introduction, Joincommunity, Whitelistlink } from '../containers';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const sort = query.get('sort') || 1;
    const page = query.get('page') || 1;
  

    return(
        <div>
            <Introduction/>
            
            
            {/* <Whitelistlink/> */}
            <Joincommunity/>
        </div>
    );

}

export default Home;
